// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-basic-compatrix-template-js": () => import("./../src/templates/basicCompatrixTemplate.js" /* webpackChunkName: "component---src-templates-basic-compatrix-template-js" */),
  "component---src-templates-stepped-template-js": () => import("./../src/templates/steppedTemplate.js" /* webpackChunkName: "component---src-templates-stepped-template-js" */)
}

